import React from "react";
import {useNavigate  } from 'react-router-dom';
function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    navigate('/login');
  }
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
     
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown">
            <button onClick={handleLogout} className="btn btn-danger ml-2">
              Sign Out
            </button>
            {/* <Link onClick={handleLogout}> <i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out</Link> */}
              {/* <i className="far fa-comments" />

              <span className="badge badge-danger navbar-badge"></span> */}
              
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Header;
