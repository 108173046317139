import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (username, password) => {
    // Replace this mock authentication with your real API call
    if (username === 'admin' && password === 'admin') {
      setUser({ username, role: 1 });
    } else if (username === 'user' && password === 'user') {
      setUser({ username, role: 2 });
    } else {
      return false;
    }
    return true;
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
