import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';

function Overspeed() {
    const [unitGroups, setUnitGroups] = useState([]);
    const [selectedUnitGroup, setSelectedUnitGroup] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [vehicleData, setVehicleData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDefaultDateTimes();
        loadUnitGroups();
    }, []);

    const setDefaultDateTimes = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        const startDateTime = `${year}-${month}-${day}T00:00`;
        const endDateTime = `${year}-${month}-${day}T23:59`;

        setStartDate(startDateTime);
        setEndDate(endDateTime);
    };

    const loadUnitGroups = async () => {
        try {
            const response = await fetch('http://localhost:8000/get-unit-groups_overspeed');
            if (!response.ok) {
                throw new Error(`Failed to fetch unit groups: ${response.statusText}`);
            }
            const data = await response.json();
            setUnitGroups(data);
        } catch (error) {
            console.error('Error loading unit groups:', error);
            alert('Failed to load unit groups.');
        }
    };

    const fetchData = async () => {
        if (!selectedUnitGroup || !startDate || !endDate) {
            alert('Please fill in all fields.');
            return;
        }

        setLoading(true);

        const requestUrl = `http://localhost:8000/map-rows-and-subrows_overspeed/?unitGroup=${selectedUnitGroup}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
        try {
            const response = await fetch(requestUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const data = await response.json();
            setVehicleData(data.map((row, index) => ({ id: index + 1, ...row }))); // Add auto-number
            if (data.length === 0) {
                alert('No data found for the selected parameters.');
            }
        } catch (error) {
            alert('An error occurred while fetching data. Please try again.');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    const exportToExcel = () => {
        const headers = [
            'No',
            'Plate Number',
            'Division',
            'Model',
            'Department',
            'Term',
            'User',
            'Fuel',
            '>=80 To <100 Kh/h',
            '>=100 To <=120 Km/h',
            '>=120Km up',
            'Total​ Overspeed',
            'Overspeed Total Minute(>=80 To <100 Kh/h)',
            'Overspeed Total Minute(>=100 To <=120 Km/h)',
            'Overspeed Total Minute(120Km up)',
            'Overspeed Total Minute',
            'Overspeed Total Hour',
            'Overspeed Violation',
            'Overspeed Non-Violation',
            'Working Hour',
            'Non-Working Hour'
            // '>=Total​ Overspeed'
        ];
    
        const rows = vehicleData.map((row, index) => [
            index + 1, // Auto-number
            row.plateNumber,
            row.division,
            row.model,
            row.department,
            row.term,
            row.user,
            row.fuel,
            row.overpseed80,
            row.overpseed100,
            row.overpseed120,
            // row.overpseed80 + row.overpseed100 + row.overpseed120
        ]);
    
        const wsData = [
            headers, // Headers
            ...rows // Data rows
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(wsData);
    
        // Apply silver background to header row
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C }); // r: 0 (first row), c: C (current column)
            if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {}; // Ensure style object exists
            worksheet[cellAddress].s.fill = {
                fgColor: { rgb: 'C0C0C0' } // Silver color
            };
            worksheet[cellAddress].s.font = {
                bold: true // Make font bold in the header
            };
        }
    
        // Auto-width columns based on data
        const maxWidths = wsData[0].map((_, colIndex) => (
            Math.max(
                ...wsData.map(row => (row[colIndex] ? row[colIndex].toString().length : 0))
            )
        ));
    
        worksheet['!cols'] = maxWidths.map(width => ({ wch: width + 2 })); // Add some padding
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    
        XLSX.writeFile(workbook, 'vehicle_data_report.xlsx');
    };

    const columns = [
        { name: 'No', selector: row => row.id, sortable: true }, // Auto-number column
        { name: 'Plate Number', selector: row => row.plateNumber, sortable: true },
        { name: 'Division', selector: row => row.division, sortable: true },
        { name: 'Model', selector: row => row.model, sortable: true },
        { name: 'Department', selector: row => row.department, sortable: true },
        { name: 'Term', selector: row => row.term, sortable: true },
        { name: 'User', selector: row => row.user, sortable: true },
        { name: 'Fuel', selector: row => row.fuel, sortable: true },
        { name: '>=80 To <100 Kh/h', selector: row => row.overpseed80, sortable: true },
        { name: '>=100 To <=120 Km/h', selector: row => row.overpseed100, sortable: true },
        { name: '=120Km up', selector: row => row.overpseed120, sortable: true },
        // { name: '=Total', selector: row => row.overpseed80 + row.overpseed100 + row.overpseed120, sortable: true },
    ];

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">OverSpeed Summaries Report</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="controls" style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start', gap: '20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="unitGroup" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>Unit Group:</label>
                                <select id="unitGroup" value={selectedUnitGroup} onChange={e => setSelectedUnitGroup(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }}>
                                    <option value="">Select a group...</option>
                                    {unitGroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.nm}</option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="startDate" style={{ marginRight: '10px', fontWeight: 'bold', fontSize:'12px' }}>Start Date:</label>
                                <input type="datetime-local" id="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="endDate" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>End Date:</label>
                                <input type="datetime-local" id="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={fetchData} style={{ padding: '5px 15px', fontSize: '12px' }}>Search</button>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={exportToExcel} style={{padding: '5px 15px', fontSize: '12px' }}>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <DataTable
                            columns={columns}
                            data={vehicleData}
                            progressPending={loading}
                            pagination
                            highlightOnHover
                            noHeader={true}  // Remove header
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Overspeed;
