import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
function IVMSReport() {
    const [unitGroups, setUnitGroups] = useState([]);
    const [selectedUnitGroup, setSelectedUnitGroup] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [vehicleData, setVehicleData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDefaultDateTimes();
        loadUnitGroups();
    }, []);
    const hiddenPlateNumber = "1HY-1446";
    const setDefaultDateTimes = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        const startDateTime = `${year}-${month}-${day}T00:00`;
        const endDateTime = `${year}-${month}-${day}T23:59`;

        setStartDate(startDateTime);
        setEndDate(endDateTime);
    };
    const loadUnitGroups = async () => {
        try {
            const response = await fetch('https://sea-turtle-app-k5gi2.ondigitalocean.app/get-unit-groups');
           // const response = await fetch('http://localhost:8000/get-unit-groups');
            if (!response.ok) {
                throw new Error(`Failed to fetch unit groups: ${response.statusText}`);
            }
            const data = await response.json();
            setUnitGroups(data);
        } catch (error) {
            console.error('Error loading unit groups:', error);
            // alert('Failed to load unit groups.');
        }
    };

    const fetchData = async () => {
        if (!selectedUnitGroup || !startDate || !endDate) {
            alert('Please select unit group.');
            return;
        }
  
        setLoading(true);

        const requestUrl = `https://sea-turtle-app-k5gi2.ondigitalocean.app/map-rows-and-subrows?unitGroup=${selectedUnitGroup}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
        try {
            const response = await fetch(requestUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const data = await response.json();
            setVehicleData(data.map((row, index) => ({ id: index + 1, ...row }))); // Add auto-number
            if (data.length === 0) {
                alert('No data found for the selected parameters.');
            }
        } catch (error) {
            alert('An error occurred while fetching data. Please try again.');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const exportToExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('IVMS Report');
    
            const headers = [
                'No',
                'SM Code',
                'Driver Name',
                'Plate Number',
                'Vehicles Type',
                'Beg Time(Hrs)',
                'End Time(Hrs)',
                'Duration(Hrs)',
                'Total Mileage(Km)',
                'Idling > 15 MN (Minute)',
                'Idling Counting of over 15 Minutes',
                'Over Speed > 3 Minutes',
                'Harsh Braking',
                'Harsh Acceleration',
                'Harsh Cornering',
                'Count 2 Hrs of Continue Driving',
                'Count 4 Hrs of Continue Driving',
                'Stop Longer than 1 hour',
                'Stop Longer than 2 hours',
                'Total Start',
                'Total Stop'
            ];
            
            worksheet.getCell('A6').value = 'IVMS Summary Report';
            worksheet.getCell('A6').font = { size: 16, bold: true };
            worksheet.getCell('A6').alignment = { vertical: 'middle', horizontal: 'left' };            
            worksheet.getCell('A7').value = 'Date:';
            worksheet.getCell('B7').value = `From: ${new Date(startDate).toLocaleDateString()}`;
            worksheet.getCell('C7').value = `To: ${new Date(endDate).toLocaleDateString()}`;
            worksheet.addRow([]);
            worksheet.addRow(headers);
            vehicleData.forEach((row, index) => {
                worksheet.addRow([
                    index + 1, // Auto-number
                    row.sm_code,
                    row.driverName,
                    row.plateNumber,
                    row.vehiclesType,
                    row.beginning,
                    row.end,
                    row.duration,
                    row.totalMileage,
                    row.idlingtime,
                    Number(row.idlingtimecount),
                    Number(row.overspeed),
                    Number(row.braking) || 0,
                    Number(row.acceleration) || 0,
                    Number(row.cornering) || 0,
                    Number(row.engineover12),
                    Number(row.engineover2),
                    Number(row.parking12),
                    Number(row.parking24),
                    Number(row.totalStartStop),
                    Number(row.totalStartStop)
                ]);
            });
        
            const imageResponse = await fetch(`${process.env.PUBLIC_URL}/logo/HIL.png`);
            const imageBuffer = await imageResponse.arrayBuffer();
            const imageId = workbook.addImage({
                buffer: imageBuffer,
                extension: 'png',
            });
    
            // Add logo in cell A1 (top-left)
            worksheet.addImage(imageId, {
                tl: { col: 0, row: 0 }, // Top-left corner (A1)
                ext: { width: 300, height: 100 } // Image size
            });
            
    
            // Auto-width for columns
            worksheet.getColumn(1).width = 5; // Set width of 'No' column to 10

            // Auto-width for other columns
            worksheet.columns.forEach(column => {
                if (column.number !== 1) { // Exclude the 'No' column
                    const maxLength = column.values.reduce((max, curr) => Math.max(max, curr?.toString().length || 0), 0);
                    column.width = Math.max(maxLength + 1, 2); // Ensure width is at least 10
                }
                column.alignment = { horizontal: 'left' };
            });
    
            // Generate Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'IVMS_Summary_Report.xlsx');
    
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };
    
    const columns = [
        { name: 'No', selector: row => row.id, sortable: true }, // Auto-number column
        { name: 'SM Code', selector: row => row.sm_code?.toString() || '', sortable: true },
        { name: 'Driver Name', selector: row => row.driverName?.toString() || '', sortable: true },
        { name: 'Plate Number', selector: row => row.plateNumber, sortable: true },
        { name: 'Vehicles Type', selector: row => row.vehiclesType, sortable: true },
        { name: 'Beg Time(Hrs)', selector: row => row.beginning, sortable: true },
        { name: 'End Time(Hrs)', selector: row => row.end, sortable: true },
        { name: 'Duration(Hrs)', selector: row => row.duration || '', sortable: true },
        { name: 'Total Mileage(Km)', selector: row => row.totalMileage || '', sortable: true },
        { name: 'Idling > 15 MN (Minute)', selector: row => row.idlingtime, sortable: true },
        { name: 'Idling Counting of over 15 Minutes', selector: row => row.idlingtimecount, sortable: true },
        { name: 'Over Speed > 3 Minutes', selector: row => row.overspeed || '', sortable: true },
        { name: 'Harsh Braking', selector: row => row.braking || '0', sortable: true },
        { name: 'Harsh Acceleration', selector: row => row.acceleration || '0', sortable: true },
        { name: 'Harsh Cornering', selector: row => row.cornering || '0', sortable: true },
        { name: 'Count 2 Hrs of Continue Driving', selector: row => row.engineover12 || '', sortable: true },
        { name: 'Count 4 Hrs of Continue Driving', selector: row => row.engineover2 || '', sortable: true },
        { name: 'Stop Longer than 1 hours', selector: row => row.parking12 || '', sortable: true },
        { name: 'Stop Longer than 2 hours', selector: row => row.parking24 || '', sortable: true },
        { name: 'Total Start', selector: row => row.totalStartStop || '', sortable: true },
        { name: 'Total Stop', selector: row => row.totalStartStop || '', sortable: true },
    ];
    
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">IVMS Report</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="controls" style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start', gap: '20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="unitGroup" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>Unit Group:</label>
                                <select id="unitGroup" value={selectedUnitGroup} onChange={e => setSelectedUnitGroup(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }}>
                                    <option value="">Select a group...</option>
                                    {unitGroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.nm}</option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="startDate" style={{ marginRight: '10px', fontWeight: 'bold', fontSize:'12px' }}>Start Date:</label>
                                <input type="datetime-local" id="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="endDate" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>End Date:</label>
                                <input type="datetime-local" id="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={fetchData} style={{ padding: '5px 15px', fontSize: '12px' }}>Search</button>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={exportToExcel} style={{padding: '5px 15px', fontSize: '12px' }}>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row">
                        <DataTable
                            columns={columns}
                            data={vehicleData.filter(row => row.plateNumber !== hiddenPlateNumber)} // Exclude the plate number
                            progressPending={loading}
                            pagination
                            highlightOnHover
                            noHeader={true}  // Remove header
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default IVMSReport;
