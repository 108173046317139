import React, {  } from 'react';

function Footer(){
  return(
  <div>
  <footer className="main-footer">
    <strong>Copyright © 2024 Mango Tracking Co.,LTD. </strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 01
    </div>
  </footer>
  {/* Control Sidebar */}
  <aside className="control-sidebar control-sidebar-dark">
    {/* Control sidebar content goes here */}
  </aside>
</div>


  );
}
export default Footer;
