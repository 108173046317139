import React, { } from 'react';

function Contat() {
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Contact</h1>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    );
}

export default Contat;
