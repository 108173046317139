import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './component/Login';
import Header from './component/Header';
import Navigation from './component/Navigation';
import Footer from './component/Footer';
import Home from './page/Home';
import Contact from './page/Contact';
import About from './page/About';
import NoPage from './page/NoPage';
import IVMSReport from './page/HIL/IVMSReport';
import OverSpeedReport from './page/RMA/OverspeedReport';
import Register from './component/Register';
import IdlingReport from './page/RMA/IdlingReport';
import PrivateRoute from './component/PrivateRoute';
import { AuthProvider } from './component/AuthContext';
import RequireAuth from './component/RequireAuth';

function App() {
  const handleLogin = () => {
    // Handle any additional login logic if needed
  };

  return (
    <AuthProvider>
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="login" element={<Login onLogin={handleLogin} />} />
            <Route path="register" element={<Register />} />

            {/* Protected Routes */}
            <Route
              path="*"
              element={
                <RequireAuth>
                  <Header />
                  <Navigation />
                  <Routes>
                    <Route path="home" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />

                    {/* Protect IVMSReport for role 1 */}
                    <Route
                      path="IVMS"
                      element={
                        <PrivateRoute allowedRoles={[1]}>
                          <IVMSReport />
                        </PrivateRoute>
                      }
                    />
                    
                    {/* Protect OverspeedReport and IdlingReport for role 2 */}
                    <Route
                      path="overspeed"
                      element={
                        <PrivateRoute allowedRoles={[2]}>
                          <OverSpeedReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="idling"
                      element={
                        <PrivateRoute allowedRoles={[2]}>
                          <IdlingReport />
                        </PrivateRoute>
                      }
                    />
                  
                    <Route path="*" element={<NoPage />} />
                  </Routes>
                  <Footer />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
