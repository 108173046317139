import React, { useState } from 'react';
const Register = ({ onRegister }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('2'); // Default role as '2' (user)
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const requestUrl = `https://sea-turtle-app-k5gi2.ondigitalocean.app/api/register`; // Replace with your actual API endpoint
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, role }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }

      const { token } = await response.json();
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('isAuthenticated', 'true');

      // Clear the error message and display the success message
      setErrorMessage('');
      setSuccessMessage('Registration successful!');
      setUsername('');
      setPassword('');
      setRole('2'); // Reset role to default (User)

      // Optionally call the onRegister function passed down as a prop
      if (onRegister) {
        onRegister();
      }

    } catch (error) {
      setErrorMessage(error.message || 'Registration failed');
    }
  };

  return (
    <div className="hold-transition register-page">
      <div className="register-box">
        <div className="card">
          <div className="card-body register-card-body">
            <center>
              <img src={`${process.env.PUBLIC_URL}/logo/Logo.png`} alt="Logo" /><br />
            </center>
            <p className="login-box-msg">Register a new membership</p>
            {successMessage && (
              <div className="alert alert-success d-flex align-items-center" role="alert">
                <i className="fas fa-check-circle mr-2"></i>
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                <i className="fas fa-exclamation-circle mr-2"></i>
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleRegister}>
              <div className="input-group mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Username" 
                  value={username}
                  onChange={(e) => setUsername(e.target.value)} 
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input 
                  type="password" 
                  className="form-control" 
                  placeholder="Password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} 
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <select 
                  className="form-control" 
                  value={role}
                  onChange={(e) => setRole(e.target.value)} 
                  required
                >
                  <option value="1">Admin</option>
                  <option value="2">User</option>
                  <option value="3">Administrator</option>
                </select>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user-tag" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">Register</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
